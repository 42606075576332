<template>
	<div class="content-wrap">
		<Spin fix v-if="loading"></Spin>
		<CtmsSearchBox @onSearch="searchHandle" @onReset="reset" searchTxt="不显示">
      <CtmsSearchBoxItem :span="1" label="年月" >
        <DatePicker
          type="month"
          placeholder="请选择"
          :transfer="true"
          style="width: 100%"
          :clearable="false"
          format="yyyy-MM"
          :value="times"
          @on-change="v => (times = v)"
        ></DatePicker>
      </CtmsSearchBoxItem>
		</CtmsSearchBox>
    <div class="calendar" :style="`height:${dataGridHeight}px`">
      <el-calendar v-model="times" >
        <!-- 这里使用的是 2.5 slot 语法，对于新项目请使用 2.6 slot 语法-->
        <template
          slot="dateCell"
          slot-scope="{date, data}" >
          <div class="date-item" @click="handleDateClick(date, data)" :class="getDateClass(date)">
            <div class="top">{{ data.day.split('-').slice(2).join('-') }}</div>
            <div class="bottom" v-if="data.day == item.calDate" v-for="(item, key) in activeday" :key="key">
              <p class="holiday holiday-n" v-if="data.day == item.calDate && item.holiday === 0">工作日</p>
              <p class="holiday holiday-y" v-if="data.day == item.calDate && item.holiday === 1">休息日</p>
            </div>
          </div>
        </template>
      </el-calendar>
    </div>
    <!--新增项目类型-->
    <Modal
      v-model="addVisible"
      :title="`日期状态(${proTypeForm.calDate})`"
      :styles="{ top: '20%' }"
      :mask-closable="false"
      @on-cancel="cancel"
    >
      <Form
        ref="proTypeForm"
        :model="proTypeForm"
        :rules="rules"
        :label-width="120"
      >
        <FormItem label="切换状态：" prop="holiday">
          <RadioGroup v-model="proTypeForm.holiday">
            <Radio :label="0">工作日</Radio>
            <Radio :label="1">休息日</Radio>
          </RadioGroup>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button @click="cancel">取消</Button>
        <Button type="primary" :loading="loading" @click="okClick">确定</Button>
      </div>
    </Modal>
	</div>
</template>

<script>
import { mapState } from "vuex"
import api from "@/api/setting/calendar"
const { getConfigMonth, updateConfigMonth } = api
import moment from "moment"

export default {
	name: "Calendar",
	data() {
		return {
			loading: false,
      times: moment().format('YYYY-MM'),
      title: "日期状态",
      addVisible: false,
      proTypeForm: {
        calDate: null,
        holiday: null
      },
      rules: {
        holiday: {
          required: true,
          message: "该项不能为空"
        }
      },
      activeday: []
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 80
		})
	},
  watch: {
    times: {
      handler() {
        this.initCalendar()
      },
      immediate: true
    }
  },
	methods: {
    getDateClass(date) {
      const dayOfWeek = date.getDay(); // 获取星期几，0 表示周日
      if (dayOfWeek === 6 || dayOfWeek === 0) { // 6 表示周六，0 表示周日
        return 'rest-day';
      } else {
        return '';
      }
    },
    // 获取日历数据
		async initCalendar() {
			this.loading = true
			const res = await getConfigMonth({
        year: moment(this.times).format('YYYY-MM').split('-')[0],
        month: moment(this.times).format('YYYY-MM').split('-')[1]
      })
			if (res) {
        this.activeday = res.data
			}
			this.loading = false
		},
    // 日历状态（工作，休息）
    handleDateClick(date, data) {
      const { day } = data
      this.proTypeForm.calDate = day
      this.$refs.proTypeForm.resetFields()
      this.addVisible = true
    },
    // 查询
		searchHandle() {
			if (!this.times.length) {
				return
			}
      this.$nextTick(() => {
        this.initCalendar()
      })
		},
    // 更新日历数据
    okClick() {
      this.$refs.proTypeForm.validate((valid) => {
        if (valid) {
          this.$asyncDo(async () => {
            this.loading = true
            await updateConfigMonth({
              ...this.proTypeForm
            })
            await this.initCalendar()
            this.$Message.success("操作成功")
            this.addVisible = false
            this.searchHandle()
            this.loading = false
          })
        }
      })
    },
    cancel() {
      this.addVisible = false
    },
    // 重置
    reset() {
      this.times = moment().format('YYYY-MM')
      this.searchHandle()
    },
	}
}
</script>

<style lang='less' scoped>
.calendar ::v-deep {
  background: #fff;
  .el-calendar__header{
    text-align: center;
    .el-calendar__title{
      flex-grow: 1;
      font-size: 18px;
    }
    .el-calendar__button-group{
        position: absolute;
        right: 39px;
    }
  }
  .el-calendar-day{
    padding:0;
    .date-item {
      display: flex;
      justify-content: space-between;
      padding:10px;
      box-sizing: border-box;
      font-size: 16px;
      height: 100%;
      .holiday{
        text-align: right;
      }
      .holiday-y{
        color:#0a84ff;
      }
      .holiday-n{
        color:#b7004d;
      }
    }
    .rest-day{
      background: #f9f9f9;
    }
  }

}
</style>
