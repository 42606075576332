import request from "@/utils/request"
export default {
	getConfigMonth: data =>
		request('/cal/view/month', {
			method: "get",
			params: data
		}),
  updateConfigMonth: data =>
		request('/cal/change', {
			method: "get",
			params: data
		})
}
